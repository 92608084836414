:root {
  --white: #FDFFFC;
  --black: #050405;
  --red: #E71D36;
  --blue: #276FBF;
  --gray: #E0E0E0;
  --gray-1: #333333;
  --gray-2: #4F4F4F;
  --gray-3: #828282;
  --gray-4: #BDBDBD;
  --hover: #F38E9A;
  --blue-hover: #92B7DD;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--black);
  color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  cursor: pointer;
}

button:active, button:focus {
  outline: none;
}

.no__background {
  background: none;
}

.no__border {
  border: none;
}

.icon {
  display: inline-block;
  width: 22px;
  height: 22px;
}

.dribble {
  mask: url(./assets/dribble.svg);
  background: var(--gray-3);
}

.twitter {
  mask: url(./assets/twitter.svg);
  background: var(--gray-3);
  width: 27px;
}

.linkedin {
  mask: url(./assets/linkedin.svg);
  background: var(--gray-3);
  width: 22px;
}

.email {
  mask: url(./assets/email.svg);
  background: var(--gray-3);
  width: 28px;
}

.dribble:hover, .twitter:hover, .linkedin:hover, .email:hover {
  background: var(--white);
}

.boring__link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.boring__list {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.boring__list>li {
  color: var(--gray-4);
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
}

.regular__bold__text {
  font-weight: bold!important;
  font-size: 1rem!important;
  line-height: 1.625rem!important;
  color: var(--white)!important;
}

.arrow__link {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625rem;
}

.arrow__link::after {
  content: "";
  display: inline-block;
  height: 8px;
  width: 19px;
  margin-left: 10px;
  mask: url(./assets/right_arrow.svg);
  background: var(--white);
}

.gray__after__arrow__link::after {
  background: var(--gray-4);
}

.gray__after__arrow__link:hover::after {
  background: var(--white);
}

.arrow__left__link {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625rem;
}

.arrow__left__link::before {
  content: "";
  display: inline-block;
  height: 8px;
  width: 19px;
  margin-right: 10px;
  mask: url(./assets/left_arrow.svg);
  background: var(--white);
}

.gray__before__arrow__link::before {
  background: var(--gray-4);
}

.gray__before__arrow__link:hover::before {
  background: var(--white);
}

.pull__left {
  float: left;
}

.pull__right {
  float: right;
}

.main__body {
  width: 39rem;
}

.main__body p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  color: var(--gray);
  margin: 40px 0;
}

.main__body>.table {
  width: 100%;
}

.table {
  display: table;
}

.table__row {
  display: table-row;
}

.table__cell {
  display: table-cell;
}

.table>.table__row .table__cell:last-child {
  text-align: right;
}

.main__body h3 {
  margin-bottom: 5px;
  margin-top: 0;
  font-weight: bold;
  font-size: 1.618rem;
}

.main__body li p {
  color: var(--gray-4);
}

.table p {
  color: var(--gray-4);
  margin-top: 5px;
}

.table .table__cell:first-child p {
  margin-bottom: 0px;
}

.image__loader {
  animation: image_loading 5s linear infinite;
}

@-webkit-keyframes image_loading {
  0% {
    background: var(--gray-3);
  }
  25% {
    background: none;
  }
  50% {
    background: var(--gray-3);
  }
  75% {
    background: none;
  }
  100% {
    background: var(--gray-3);
  }
}

@keyframes image_loading {
  0% {
    background: var(--gray-3);
  }
  25% {
    background: none;
  }
  50% {
    background: var(--gray-3);
  }
  75% {
    background: none;
  }
  100% {
    background: var(--gray-3);
  }
}

@media (min-width: 1800px) {
  html {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .main__body {
    width: 100%;
  }
  .main__body p {
    margin: 20px 0;
  }
  .table__cell {
    display: block;
    width: 100%;
    text-align: left!important;
  }
  .table__cell p {
    margin: 5px 0px;
  }
}