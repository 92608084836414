.main__container {
  width: 200px;
  height: 20px;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

.main__container p {
  color: var(--gray-3);
  letter-spacing: 1em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625rem;
  opacity: 0;
  animation: disappear 2.5s linear infinite;
  -webkit-animation: disappear 2.5s linear infinite;
}

@media screen and (max-width: 800px) {
  .main__container {
    width: 100%;
    top: 45%;
    left: 0;
    margin: 0;
  }
}

@-webkit-keyframes disappear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes disappear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}